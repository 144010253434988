<template>
  <header class="container-fluid toolbar">
    <b-row align-v="center">
      <div>
        <a href="/" title="UX Facts" @click="openLink('Menu logo')">
          <img alt="UX Facts" class="logo" src="../assets/img/logo.svg" height="20" width="146">
        </a>
      </div>
      <input type="text" id="pageShare" v-model="pageShare" name="uxfactsLink">
      <div class="icons">
        <div class="icon" @click="restart" v-b-tooltip.hover.bottom title="Restart">
          <i class="fas fa-sync"></i>
        </div>
        <div class="icon share" :class="{'opened': sharing}">
          <div v-b-tooltip.hover.bottom title="Share" @click="toggleShare">
            <i class="fas" :class="sharing ? 'fa-times': 'fa-share'"></i>
          </div>
          <transition name="fadeRight">
            <div class="platforms" v-if="sharing" style="animation-duration: 0.35s">
              <ShareNetwork
                network="facebook"
                url="https://uxfacts.com/"
                :title="getShareText"
                :quote="getShareText"
              >
                <div class="icon" v-b-tooltip.hover.left title="Facebook">
                  <i class="fab fa-facebook-f"></i>
                </div>
              </ShareNetwork>
              <ShareNetwork
                network="Twitter"
                url="https://uxfacts.com/"
                :title="getShareText"
              >
                <div class="icon" v-b-tooltip.hover.left title="Twitter">
                  <i class="fab fa-twitter"></i>
                </div>
              </ShareNetwork>
              <ShareNetwork
                network="LinkedIn"
                url="https://uxfacts.com/"
                :title="getShareText"
              >
                <div class="icon" v-b-tooltip.hover.left title="LinkedIn">
                  <i class="fab fa-linkedin-in"></i>
                </div>
              </ShareNetwork>
              <div class="icon" v-b-tooltip.hover.left @click="copyLink" title="Copy link">
                <i class="fas fa-link"></i>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </b-row>
  </header>
</template>

<script>
import {EventBus} from '@/event-bus'
import {mapState} from "vuex";

export default {
	name: 'toolbar',
	data () {
		return {
			sharing: false,
			pageShare: 'https://uxfacts.com/'
		}
	},
	computed: {
		...mapState('Questions', [
			'score', 'gameOver', 'questionQueue'
		]),
		getShareText () {
			if (this.gameOver) {
				return `Just reached ${this.score}/${this.questionQueue.length} ${this.score > 0 ? 'points' : 'point'} in the UXfacts quiz!`
			} else {
				return "Try the UXfacts quiz!"
			}
		}
	},
	methods: {
		restart () {
			EventBus.$emit('restart')
			this.$analytics.logEvent('action', {
				'event_label': 'Menu restart',
				'event_category': 'Click',
				'non_interaction': true
			});
		},
		toggleShare () {
			this.sharing = !this.sharing
			this.$analytics.logEvent('action', {
				'event_label': 'Menu share',
				'event_category': 'Click',
				'non_interaction': true
			});
		},
		copyLink () {
			document.getElementById('pageShare').select();
			document.execCommand('copy');
		},
		openLink (label) {
			this.$analytics.logEvent('open_link', {
				'event_label': label,
				'event_category': 'Click',
				'non_interaction': true
			});
		}
	},
	mounted () {
		EventBus.$on('share', () => {
			this.toggleShare()
		})
	}
}
</script>
<style scoped>
#pageShare {
    height: 1px;
    opacity: 0;
    width: 10px;
    padding: 0;
    border: none;
    outline: none;
}
</style>