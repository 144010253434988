<template>
  <div class="score">
    <div class="score-header">
      <div class="subtitle">
        Thanks for playing with UXfacts!
      </div>
      <h1>
        Your result: {{ score }}/{{ questionQueue.length }}
      </h1>
    </div>
    <div class="score-body">
      <a href="https://chrome.google.com/webstore/detail/heurio-ux-review-visual-f/pjdbofhiijhapnmpgilkeammkanglfdj" target="_blank" title="Try Heurio" @click="openLink('Try Heurio')">
        <img src="../assets/img/score.png" alt="Lets try Heurio">
      </a>
    </div>
    <div class="score-footer">
      <button @click="share" class="btn btn-secondary"><i class="fas fa-arrow-left"></i> Share</button>
      <button @click="restart" class="btn btn-primary">Play again <i class="fas fa-arrow-right"></i></button>
    </div>
  </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {mapState} from "vuex";

export default {
	name: 'score',
	props: [],
	computed: {
		...mapState('Questions', [
			'score', 'questionQueue', 'gameOver'
		])
	},
	created () {
		EventBus.$on('keyUp', (keyCode) => {
			if (this.gameOver) {
				this.eventKeyHandler(keyCode)
			}
		})
	},
	methods: {
		eventKeyHandler (keyCode) {
			switch (keyCode) {
				case 37: // left
					this.share();
					break;
				case 39: // right
					this.restart();
					break;
			}
		},
		restart () {
			this.$analytics.logEvent('action', {
				'event_label': 'Play again',
				'event_category': 'Click',
				'non_interaction': true
			});
			EventBus.$emit('restart')
		},
		share () {
			this.$analytics.logEvent('action', {
				'event_label': 'Share',
				'event_category': 'Click',
				'non_interaction': true
			});
			EventBus.$emit('share')
		},
		openLink (label) {
			this.$analytics.logEvent('open_link', {
				'event_label': label,
				'event_category': 'Click',
				'non_interaction': true
			});
		}
	}
}
</script>