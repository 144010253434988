<template>
  <div class="container-fluid cards">
    <div v-if="!gameOver">
      <div class="card-container">
        <div class="results">Correct answers:<span>{{ score }}</span></div>
        <card :number="active" @questionAnswered="updateStat" @nextQuestion="nextQuestion" :question="questionQueue[active]" :front="true"></card>
        <transition name="fade">
          <card :number="active + 1" v-if="questionQueue[active + 1]" :question="questionQueue[active + 1]" :front="false"></card>
        </transition>
      </div>
      <div class="progress">
        <div class="progress-bar" role="progressbar" :style="{'width': `${Math.floor((active + 1) / questionQueue.length * 100)}%`}">
          <div class="bar-label">{{ active + 1 }}/{{ questionQueue.length }}</div>
        </div>
      </div>
    </div>
    <score v-show="gameOver"></score>
    <footer class="footer">
      <p>
        <a href="https://heur.io" target="_blank" rel="noopener" title="Heurio" @click="openLink('Heurio website')">Built with <i class="fas fa-heart"></i> by Heurio</a>
      </p>
      <p>
        <a href="http://eepurl.com/hgq9bL" target="_blank" rel="noopener" title="Subscribe to UXfacts newsletter" @click="openLink('Subscribe')">Subscribe to UXfacts newsletter</a>
      </p>
      <p>
        <a href="https://us2.list-manage.com/survey?u=4c665306da6cb8ec58703106a&id=e3f5d1d6ab" @click="openLink('Send a UX fact')" target="_blank" rel="noopener" title="Send us an interesting UX fact!">Send us an interesting UX fact!</a>
      </p>
    </footer>
  </div>
</template>

<script>
import Card from "@/components/card";
import {mapState} from "vuex";
import {EventBus} from '@/event-bus'
import Score from "@/components/score";

export default {
	name: 'cards',
	components: {
		Score,
		Card
	},
	data () {
		return {
			active: 0
		}
	},
	computed: {
		...mapState('Questions', [
			'questions', 'score', 'gameOver', 'questionQueue'
		])
	},
	created () {
		this.init()
	},
	mounted () {
		window.addEventListener('keyup', this.keyupHandler);

		EventBus.$on('restart', () => {
			this.init()
		})
	},
	methods: {
		init () {
			this.active = 0
			this.$store.commit('Questions/setScore', 0)
			this.$store.commit('Questions/setQuestionQueue', [])
			this.$store.commit('Questions/setGameOver', false)

			let remainingQuestions = [].concat(this.questions);

			while (this.questionQueue.length < 10 && remainingQuestions.length) {
				let nextQuestion = Math.floor(Math.random() * remainingQuestions.length)
				this.$store.commit('Questions/addQuestionToQueue', remainingQuestions[nextQuestion])
				remainingQuestions.splice(nextQuestion, 1)
			}
		},
		keyupHandler (e) {
			switch (e.keyCode) {
				case 37: // left
				case 39: // right
				case 13: // enter
					EventBus.$emit('keyUp', e.keyCode)
					break;
			}
		},
		nextQuestion () {
			if (this.questionQueue[this.active + 1]) {
				this.active++
			} else {
				this.$store.commit('Questions/setGameOver', true)
			}
		},
		updateStat (goodAnswer) {
			if (goodAnswer) this.$store.commit('Questions/increaseScore')
		},
		openLink (label) {
			this.$analytics.logEvent('open_link', {
				'event_label': label,
				'event_category': 'Click',
				'non_interaction': true
			});
		}
	},
	beforeDestroy () {
		window.removeEventListener('keyup', this.keyupHandler);
	}
}
</script>