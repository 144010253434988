<template>
  <div id="app">
    <toolbar></toolbar>
    <cards></cards>
  </div>
</template>

<script>
import Toolbar from "@/components/toolbar";
import Cards from "@/components/cards";

export default {
	name: 'App',
	components: {
		Toolbar,
		Cards
	}
}
</script>