<template>
  <div class="ux-card" :class="{'active': front}">
    <div class="ux-card-header">
      <div class="card-category">
        {{ question.category }}
      </div>
    </div>
    <div class="ux-card-body">
      <div class="ui-card-body-inner">
        {{ question.question }}
        <transition name="fade" duration="250">
          <div v-if="selected !== null" style="animation-duration: 0.3s" class="source"><a :href="question.source" @click="openSource" :title="question.source" target="_blank">{{ question.answers[0].value }}</a></div>
        </transition>
      </div>
    </div>
    <div class="ux-card-answers">
      <div @click="selectAnswer('left')" class="answer" :class="{'full': selected === order.left, 'fading': selected === order.right, 'correct': selected === order.left && question.answers[order.left].correct, 'incorrect': selected === order.left && question.answers[order.right].correct}">
        <div class="answer-inner">
          <span v-if="selected === null" class="answer-item"><i class="fas fa-arrow-left"></i>{{ question.answers[order.left].value }}</span>
          <span v-if="selected !== null" class="next-item"><i class="fas fa-check-circle"></i><i class="fas fa-times-circle"></i> Next <span class="next-icon">Press enter</span></span>
        </div>
      </div>
      <div @click="selectAnswer('right')" class="answer" :class="{'fading': selected === order.left,'full': selected === order.right, 'correct': selected === order.right && question.answers[order.right].correct, 'incorrect': selected === order.right && question.answers[order.left].correct}">
        <div class="answer-inner">
          <span v-if="selected === null" class="answer-item">{{ question.answers[order.right].value }} <i class="fas fa-arrow-right"></i></span>
          <span v-if="selected !== null" class="next-item"><i class="fas fa-check-circle"></i><i class="fas fa-times-circle"></i> Next <span class="next-icon">Press enter</span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import {mapState} from "vuex";

export default {
	name: 'card',
	props: [
		'front',
		'question',
		'number'
	],
	data () {
		return {
			order: {},
			selected: null
		}
	},
	computed: {
		...mapState('Questions', [
			'gameOver'
		])
	},
	methods: {
		eventKeyHandler (keyCode) {
			switch (keyCode) {
				case 37: // left
					if (this.selected === null) {
						this.selectAnswer('left')
					}
					break;
				case 39: // right
					if (this.selected === null) {
						this.selectAnswer('right')
					}
					break;
				case 13: // enter
					if (this.selected !== null) {
						this.nextQuestion()
					}
					break;
			}
		},
		selectAnswer (direction) {
			if (this.selected == null) {
				this.selected = this.order[direction]
				// update parent
				let correctAnswer = (this.selected === this.order.right && this.question.answers[this.order.right].correct || (this.selected === this.order.left && this.question.answers[this.order.left].correct))
				this.$emit('questionAnswered', correctAnswer)

				this.$analytics.logEvent('answer', {
					'event_label': `${this.number}. answer`,
					'event_category': 'Click',
					'event_value': direction,
					'non_interaction': true
				});
			} else {
				this.nextQuestion()
			}
		},
		shuffleAnswers () {
			this.order.left = Math.floor(Math.random() * 2)
			if (this.order.left === 0) {
				this.order.right = 1
			} else {
				this.order.right = 0
			}
		},
		nextQuestion () {
			this.$emit('nextQuestion')
			this.selected = null
			this.shuffleAnswers()
		},
		openSource (e) {
			e.target.blur()
			this.$analytics.logEvent('source', {
				'event_label': `Source link`,
				'event_category': 'Click',
				'non_interaction': true
			});
		}
	},
	created () {
		this.shuffleAnswers()

		EventBus.$on('keyUp', (keyCode) => {
			if (this.front) {
				this.eventKeyHandler(keyCode)
			}
		})

		EventBus.$on('restart', () => {
			this.selected = null
			this.shuffleAnswers()
		})
	}
}
</script>